import { TrackEventInput } from '@updater/ui-tracker';
import { Flex, Palette, Text, TextLink } from '@updater/ui-uds';
import {
  DOLLY_SUPPORT_NUMBER,
  MOVE_FLOW_DOMAINS,
} from 'flows/moving/constants';
import { Phone } from '@phosphor-icons/react';
import React from 'react';
import { useTracking } from 'react-tracking';

export type QuestionsTextWithLinkOutProps = React.ComponentProps<typeof Text>;

export const QuestionsTextWithLinkOut: React.FC<
  QuestionsTextWithLinkOutProps
> = ({ ...props }) => {
  const { trackEvent } = useTracking<TrackEventInput<unknown>>({
    domain: MOVE_FLOW_DOMAINS.MOVING_TAB,
  });

  return (
    <Text as="p" variant="m" {...props}>
      Questions, or prefer to book via phone?
      <Flex alignItems="center">
        <Phone color={Palette.royalBlue} size={18} />
        <TextLink
          data-testid="dolly-support-number-text-link"
          marginLeft="xxs"
          variant="m"
          href={`tel:${DOLLY_SUPPORT_NUMBER}`}
          onClick={() =>
            trackEvent({
              object: 'dolly-support-number-text-link',
              verb: 'clicked',
              details: {
                phoneNumber: DOLLY_SUPPORT_NUMBER,
              },
            })
          }
        >
          {DOLLY_SUPPORT_NUMBER}
        </TextLink>
      </Flex>
    </Text>
  );
};
