import {
  useFeature as useGrowthbookFeature,
  useGrowthBook,
  type FeatureResult,
} from '@growthbook/growthbook-react';

export interface UseFeatureReturn extends FeatureResult {
  loading: boolean;
}

export function useFeature(id: string): UseFeatureReturn {
  const gb = useGrowthBook();
  const feature = useGrowthbookFeature(id);

  // Default to false to match gb defaults
  const loading = !gb?.ready ?? false;

  return {
    ...feature,
    loading,
  };
}
