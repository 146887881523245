import { omitKeysForLogging as xfinityOmitKeys } from 'flows/tvi/utils/buy/config/xfinity/form-store';
import { omitKeysForLogging as attOmitKeys } from 'flows/tvi/utils/buy/config/att/form-store';
import { omitKeysForLogging as coxOmitKeys } from 'flows/tvi/utils/buy/config/cox/form-store';
import { ContactInformation } from '../utils/buy/config/common/types';

// These are fields that we might store in local storage
// but wouldn’t track on events.
const extraSensitiveFields = ['dob'];

export const SensitiveFields = Array.from(
  new Set([
    ...xfinityOmitKeys,
    ...attOmitKeys,
    ...coxOmitKeys,
    ...extraSensitiveFields,
  ])
);

export enum BuyPages {
  Modems = 'modems',
  Payment = 'payment-method',
  Option = 'payment-option',
  Info = 'payment-info',
  Review = 'buy/review',
  Dob = 'dob',
  Submission = 'buy/confirmation',
  Error = 'buy/error',
  CurrentAddressLongevity = 'current-address-longevity',
  Identity = 'identity',
  SecureAccount = 'secure-account',
  ScheduleInstallation = 'schedule-installation',
}

export enum BuyAnalyticsEvents {
  Error = 'buy_flow_error',
  MODEMS = 'buy_flow_internet_service_modems',
  DOB = 'buy_flow_account-creation',
  PAYMENT_OPTION = 'buy_flow_payment-option',
  PAYMENT_INFO = 'buy_flow_credit_card_payment',
  PAYMENT_METHOD = 'buy_flow_credit_card_payment',
  SUBMISSION = 'buy_flow_confirmation',
  REVIEW = 'buy_flow_order_review',
  MODAL_MODEMS = 'buy_flow_modal_modems',
  MODAL_STREAMING_DEVICE = 'buy_flow_modal_streaming_device',
  MODAL_AUTOPAY = 'buy_flow_modal_autopay',
  MODAL_DEPOSIT = 'buy_flow_modal_deposit',
  MODAL_FULL_PLAN = 'buy_flow_modal_full-plan',
  MODAL_EARLY_TERMINATION = 'buy_flow_modal_early-termination',
  RESERVATION_FAIL = 'buy_flow_reservation-fail',
  CURRENT_ADDRESS_LONGEVITY = 'buy_flow_current-address-longevity',
  IDENTITY = 'buy_flow_identity',
  SECURE_ACCOUNT = 'buy_flow_secure-account',
  SCHEDULE_INSTALLATION = 'buy_flow_schedule-installation',
  SERVICES = 'buy_flow_services',
  INTERNET_SPEED_RANGE = 'buy_flow_internet-speed-range',
  INTERNET_PLAN = 'buy_flow_internet-plan',
  TV_PROVIDER = 'buy_flow_tv-provider',
  INTERNET_EQUIPMENT = 'buy_flow_internet-equipment',
  TV_CHANNELS = 'buy_flow_tv-channels',
  ADD_TV = 'buy_flow_add-tv',
  EDIT_OFFER = 'buyflow_edit_offer',
}

export const XFinityContactInformation: ContactInformation = {
  submissionContactNumber: '866-853-4518',
  buyFlowContactNumber: '844-341-4838',
  completedSubmissionContactNumber: '1-877-561-9475',
};

export const ProfessionalInstallationFee = 99;

export const ATTContactInformation: ContactInformation = {
  submissionContactNumber: '877-782-3558',
  buyFlowContactNumber: '844-808-8418',
  completedSubmissionContactNumber: '1-866-861-6075',
};

export const CoxContactInformation: ContactInformation = {
  submissionContactNumber: '877-782-3558',
  buyFlowContactNumber: '844-808-8418',
  // the following should be updated with correct number
  completedSubmissionContactNumber: '800-234-3993',
};

export const SpectrumContactInformation: ContactInformation = {
  submissionContactNumber: '877-782-3558',
  buyFlowContactNumber: '844-808-8418',
  // the following should be updated with correct number
  completedSubmissionContactNumber: '800-234-3993',
};
