import create, { SetState, GetState, StoreApi } from 'zustand';
import { persist } from 'zustand/middleware';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

const includeKeys = ['status', 'formData', 'pageRoute'];
export const BuyStorageKey = 'upd.tvi.buyflow.att.self.install';

export enum Installation {
  SELF_INSTALL = 'selfInstall',
  PRO_INSTALL_FREE = 'proInstallFree',
  PRO_INSTALL_PAID = 'proInstallPaid',
}

export enum ContractTerm {
  ONE_YEAR = 'oneYear',
  TWO_YEAR = 'twoYear',
  REMOVE = 'remove',
}

export type Form<FormData, BuyPages> = {
  status: 'loading' | 'loaded';
  formData: Partial<FormData>;
  pageRoute: BuyPages;
  paymentCardTokenize?: () => void;
  setPaymentCardTokenize: ({ tokenize }: { tokenize: () => void }) => void;
  ssnTokenize: () => void;
  setSsnTokenize: ({ tokenize }: { tokenize: () => void }) => void;
  dobTokenize: () => void;
  setDobTokenize: ({ tokenize }: { tokenize: () => void }) => void;
  passcodeTokenize: () => void;
  setPasscodeTokenize: ({ tokenize }: { tokenize: () => void }) => void;
  securityAnswerTokenize: () => void;
  setSecurityAnswerTokenize: ({ tokenize }: { tokenize: () => void }) => void;
  updateFormData: ({ formData }: { formData: Partial<FormData> }) => void;
  updateRoute: ({ route }: { route: BuyPages }) => void;
  initializeFormData: ({ formData }: { formData: Partial<FormData> }) => void;
  resetFormData: () => void;
};

export function createFormStore<Data, BuyPages>(
  initialPageRoute: BuyPages,
  initialFormData: Partial<Data>,
  omitKeys: string[]
) {
  const useStore = create(
    persist<
      Form<Data, BuyPages>,
      SetState<Form<Data, BuyPages>>,
      GetState<Form<Data, BuyPages>>,
      StoreApi<Form<Data, BuyPages>>
    >(
      (set) => ({
        status: 'loading',
        formData: initialFormData,
        pageRoute: initialPageRoute,
        // Undefined by default so `buy-flow.tsx` can check if it has been set by the tokenex components.
        paymentCardTokenize: undefined,
        setPaymentCardTokenize: ({ tokenize }) =>
          set(() => ({ paymentCardTokenize: tokenize })),
        ssnTokenize: () => {},
        setSsnTokenize: ({ tokenize }) =>
          set(() => ({ ssnTokenize: tokenize })),
        dobTokenize: () => {},
        setDobTokenize: ({ tokenize }) =>
          set(() => ({ dobTokenize: tokenize })),
        passcodeTokenize: () => {},
        setPasscodeTokenize: ({ tokenize }) =>
          set(() => ({ passcodeTokenize: tokenize })),
        securityAnswerTokenize: () => {},
        setSecurityAnswerTokenize: ({ tokenize }) =>
          set(() => ({ securityAnswerTokenize: tokenize })),
        initializeFormData: ({ formData }) =>
          set(() => ({ status: 'loaded', formData })),
        updateFormData: ({ formData }) => set(() => ({ formData })),
        updateRoute: ({ route }) => set(() => ({ pageRoute: route })),
        // can be used to reset the form data in localStorage
        // and tells the store to re-init itself next time it is used
        // by resetting status back to loading
        resetFormData: () =>
          set(() => ({
            formData: initialFormData,
            status: 'loading',
          })),
      }),
      {
        name: BuyStorageKey,
        getStorage: () => localStorage,
        partialize: (state) => omit(pick(state, includeKeys), omitKeys),
      }
    )
  );

  return useStore;
}
