import { ContractTerm } from '../utils/buy/config/common';
import { Installation } from '../utils/buy/order-summary';

// TODO: This may need a new place when we move more code over
export type ArrayContent = {
  type?: 'p-block-s' | 'p-block-m' | 'note-block';
  title?: string;
  html?: string;
  bullets?: string[];
}[];

export type Plan = PriceSchemaEntry & {
  internetSpeedMbps?: number;
  internetChoice?: InternetPlan;
  tvChoice?: TvPlan;
  installation?: Installation;
};

export type InternetPlan = PriceSchemaEntry & {
  internetSpeedMbps: number;
};

export type TvPlan = PriceSchemaEntry & {
  tvChannelCount: string;
};

export type OrderSummaryItem = {
  id: string;
  label: string;
  price: number;
  labelAdditionalInfo?: string;
  priceAdditionalInfo?: string;
  whatIs?: {
    title?: string;
    content?: string;
    htmlContent?: string;
    arrayContent?: ArrayContent;
    trackEvent?: string;
  };
  notes: {
    autopayDiscount?: string;
    [key: string]: string | undefined;
  };
};

export type OrderSummary = {
  provider?: string;
  notes: {
    feesMayChange?: string;
  };
  monthlyFees: {
    title?: string;
    plan: Plan;
    otherItems?: OrderSummaryItem[];
    priceTotal: [string, number];
  };
  dueToday?: {
    title?: string;
    items: OrderSummaryItem[];
    priceTotal?: [string, number];
  };
  dueOnFirstMonth?: {
    title?: string;
    items: OrderSummaryItem[];
    priceTotal?: [string, number];
  };
  ui?: {
    showDueToday?: boolean;
    zeroPriceLabel?: string;
  };
};

export type Order = {
  plan: Plan;
  oneTimeFees: CartItem[];
  firstMonthFees: CartItem[];
  monthlyFees: CartItem[];
  monthlyFeesTotal: number;
  dueToday: number;
  dueOnFirstMonth: number;
};

export type CartItem = {
  id: string;
  type: LineItemEnum;
  billingFrequency: BillingFrequecyEnum;
  label: string;
  originalPrice?: number;
  price: number;
  priceAdditionalInfo?: string;
  labelAdditionalInfo?: string;
  showInCart?: boolean;
  onlyInPages?: string[]; // Only show this cart item in specific pages
  notes?: { [key: string]: string };
  whatIs?: { [key: string]: string | ArrayContent };
  internetChoice?: InternetPlan;
  tvChoice?: TvPlan;
};

export type PriceSchemaEntry = CartItem & {
  priceDescription?: string;
};

export type PriceSchema = Record<string, Record<string, PriceSchemaEntry>>;

export enum LineItemEnum {
  PLAN = 'plan',
  HARDWARE = 'hardware',
  DISCOUNT = 'discount',
  FEE = 'fee',
}

export enum BillingFrequecyEnum {
  MONTHLY = 'month',
  ONETIME = 'one-time',
  FIRST_MONTH = 'first-month',
  YEARLY = 'year',
}

export type CartData = {
  plan: Partial<CartItem> & {
    contractTerm?: ContractTerm;
    internetSpeedMbps?: number;
  };
  items: Record<string, CartItem>;
  itemList: string[];
};
