import { useEffect } from 'react';

import { useUserData } from 'flows/core/hooks/use-profile';

import { Address, CreditCard, PiiTokenizedData } from 'flows/tvi/types';
import { useReservation } from 'flows/tvi/hooks/use-reservation';
import { createFormStore } from 'flows/tvi/utils/buy/config/common/form-store';
import {
  HomeServicesReservation,
  InstallationOptionsEnum,
} from '@updater/consumer-graph';
import { useFeature, useFeatureValue } from '@growthbook/growthbook-react';

export type ATTFormData = {
  ssn: string | PiiTokenizedData;
  dob: string | PiiTokenizedData;
  passcode: string | PiiTokenizedData;
  securityQuestion: string;
  securityAnswer: string | PiiTokenizedData;
  date1: string;
  date1InstallationWindow: Array<string>;
  date2: string;
  date2InstallationWindow: Array<string>;
  date3: string;
  date3InstallationWindow: Array<string>;
  flexDevices?: string;
  creditCheckMethod: 'autopay' | 'manual';
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  smsOptIn: 'sms'[];
  currentAddress?: Address;
  creditCard: Partial<CreditCard>;
  shippingAddress?: string;
  newShippingAddress?: Address;
  /*
  This field is not UI visible
  It is used by informant to conditionally render the `Schedule Installation` Screen
  based if the user has self install enabled or not
*/
  installationType: InstallationOptionsEnum;
};

export enum AttPages {
  IDENTITY = 'identity',
  SECURE_ACCOUNT = 'secure-account',
  SCHEDULE_INSTALLATION = 'schedule-installation',
  PAYMENT_OPTION = 'payment-option',
  PAYMENT = 'payment-method',
  REVIEW = 'buy/review',
  SUBMISSION = 'submission',
  ERROR = 'error',
}

export const omitKeys = [
  'formData.dob',
  'formData.ssn',
  'formData.passcode',
  'formData.securityQuestion',
  'formData.securityAnswer',
  'formData.creditCard.pci',
  'formData.creditCard.expirationDate',
];

export const omitKeysForLogging = [
  'dob',
  'ssn',
  'passcode',
  'securityQuestion',
  'securityAnswer',
  'creditCard',
];

export type UseStoreWrapper = typeof useStore;
const partialFormDataFromReservation = (
  reservation: HomeServicesReservation
) => {
  const installationTypeFromReservation =
    reservation?.offer?.options?.installationOptions?.type;
  const defaultInstallWindowTime =
    installationTypeFromReservation === InstallationOptionsEnum.self
      ? ['8am to 6pm']
      : undefined;
  return {
    installationType:
      installationTypeFromReservation as InstallationOptionsEnum,
    date1InstallationWindow: defaultInstallWindowTime,
  };
};

const useStore = createFormStore<ATTFormData, AttPages>(
  AttPages.IDENTITY,
  {},
  omitKeys
);

export function getATTFormState() {
  return useStore.getState();
}

export const useATTStore = (...args: Parameters<typeof useStore>) => {
  const status = useStore((state) => state.status);
  const initializeFormData = useStore((state) => state.initializeFormData);
  const { reservation } = useReservation('network-only');

  /**
   * Ticket - https://updater.atlassian.net/browse/TVI-1070
   * Growthbook - https://growthbook.prod.updatron.tools/features/consumer_app_tvi_att_ach_discount
   */
  const attAchAutopayDiscountEnabled = useFeatureValue(
    'consumer_app_tvi_att_ach_discount',
    false
  );

  /**
   * Ticket - https://updater.atlassian.net/browse/TVI-1322
   * Growthbook - https://growthbook.prod.updatron.tools/features/consumer_app_att_no_payment
   */
  const { value: attRemovePaymentEnabled } = useFeature(
    'consumer_app_att_remove_payment'
  );

  const { loading: userDataLoading, user } = useUserData();

  const hasHydrated = useStore.persist.hasHydrated();

  const installationTypeFromReservation =
    reservation?.offer?.options?.installationOptions?.type;

  useEffect(() => {
    if (
      hasHydrated &&
      installationTypeFromReservation &&
      status !== 'loaded' &&
      !userDataLoading
    ) {
      const initialData: Partial<ATTFormData> = {
        ...partialFormDataFromReservation(reservation),
        creditCheckMethod: attRemovePaymentEnabled ? 'manual' : 'autopay',
        flexDevices: 'one',
        firstName: user?.firstName ?? undefined,
        lastName: user?.lastName ?? undefined,
        email: user?.email ?? undefined,
        phone: user?.phone ?? undefined,
        creditCard: {
          firstName: user?.firstName ?? undefined,
          lastName: user?.lastName ?? undefined,
          address: <Address>{
            street: user?.currentMove?.fromAddress.street ?? undefined,
            unit: user?.currentMove?.fromAddress.unit ?? '',
            city: user?.currentMove?.fromAddress.city ?? undefined,
            state: user?.currentMove?.fromAddress.state ?? undefined,
            postalCode: user?.currentMove?.fromAddress.postalCode ?? undefined,
          },
          paymentMethod:
            attAchAutopayDiscountEnabled && !attRemovePaymentEnabled
              ? 'debit'
              : undefined,
        },
      };

      initializeFormData({
        formData: initialData,
      });
    }
  }, [
    hasHydrated,
    status,
    userDataLoading,
    user,
    initializeFormData,
    attAchAutopayDiscountEnabled,
    installationTypeFromReservation,
    reservation,
    attRemovePaymentEnabled,
  ]);

  return useStore(...args);
};
