export enum InternetProvider {
  Att = 'att',
  Xfinity = 'xfinity',
  CenturyLink = 'centurylink',
  EarthLink = 'earthlink',
  Cox = 'cox',
  Optimum = 'optimum',
  Frontier = 'frontier',
  Verizon = 'verizon',
  Spectrum = 'spectrum',
}
