import { useEffect } from 'react';

import { Address, CreditCard, PiiTokenizedData } from 'flows/tvi/types';
import { useUserData } from 'flows/core/hooks/use-profile';

import { createFormStore, Installation } from '../common/form-store';

export type CoxFormData = {
  internetModemChoice: string;
  date1: string;
  date1InstallationWindow: Array<string>;
  date2: string;
  date2InstallationWindow: Array<string>;
  date3: string;
  date3InstallationWindow: Array<string>;
  hasLivedAtCurrentAddressForMoreThan6Months: 'Yes' | 'No';
  ssn: string | PiiTokenizedData;
  dob: string | PiiTokenizedData;
  passcode: string | PiiTokenizedData;
  securityQuestion: string;
  securityAnswer: string | PiiTokenizedData;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  smsOptIn: 'sms'[];
  creditCard?: Partial<CreditCard>;
  /*
  This field is not UI visible
  It is used by informant to conditionally render the `Schedule Installation` Screen
  based if the user has self install enabled or not
*/
  installation: Installation;
  priorAddress?: { address: Address };
};

export enum CoxPages {
  MODEM_CHOICE = 'modems',
  CURRENT_ADDRESS_LONGEVITY = 'current-address-longevity',
  IDENTITY = 'identity',
  SECURE_ACCOUNT = 'secure-account',
  SCHEDULE_INSTALLATION = 'schedule-installation',
  PAYMENT_OPTION = 'payment-option',
  PAYMENT = 'payment-method',
  REVIEW = 'buy/review',
  SUBMISSION = 'submission',
  ERROR = 'error',
}

// TODO add the other keys we don't want to persist
export const omitKeys = [
  'formData.dob',
  'formData.ssn',
  'formData.passcode',
  'formData.securityQuestion',
  'formData.securityAnswer',
  'formData.creditCard.pci',
  'formData.creditCard.expirationDate',
];

export const omitKeysForLogging = [
  'dob',
  'ssn',
  'passcode',
  'securityQuestion',
  'securityAnswer',
  'creditCard',
];

const useStore = createFormStore<CoxFormData, CoxPages>(
  CoxPages.MODEM_CHOICE,
  {},
  omitKeys
);

export function getCoxFormState() {
  return useStore.getState();
}

export const useCoxStore = (...args: Parameters<typeof useStore>) => {
  const status = useStore((state) => state.status);
  const initializeFormData = useStore((state) => state.initializeFormData);

  const { loading: userDataLoading, user } = useUserData();

  const hasHydrated = useStore.persist.hasHydrated();

  useEffect(() => {
    if (hasHydrated && status !== 'loaded' && !userDataLoading) {
      const userFirstName = user?.firstName || undefined;
      const userLastName = user?.lastName || undefined;
      const userEmail = user?.email || undefined;
      const userPhoneNumber = user?.phone || undefined;
      const { fromAddress } = user?.currentMove || {};

      const initialData: Partial<CoxFormData> = {
        installation: Installation.SELF_INSTALL,
        internetModemChoice: 'internetModemOwned',
        firstName: userFirstName,
        lastName: userLastName,
        email: userEmail,
        phone: userPhoneNumber,
        creditCard: {
          firstName: userFirstName,
          lastName: userLastName,
          address: fromAddress,
        },
      };

      initializeFormData({
        formData: initialData,
      });
    }
  }, [hasHydrated, status, userDataLoading, user, initializeFormData]);

  return useStore(...args);
};
