import {
  CardData,
  CardDataWithHeroExtras,
  BadgeConfig,
  ReceiptPayload,
} from 'flows/core/types/cards';
import { ItemKinds } from 'types';
import {
  DataForCards,
  VerticalCardDetails,
} from 'flows/core/hooks/use-data-for-cards';
import { EssentialCards } from 'flows/core/types/layouts/essentials';
import {
  enabledItemDefOfKind,
  itemOfKind,
  Badges,
  isTVICompleted,
  findMostRecentTVIByStatus,
} from './utils';
import {
  Item,
  HomeServicesReservation,
  HomeServicesOfferServices,
} from '@updater/consumer-graph';
import { isOfferInAttAchExperiment } from 'flows/tvi/components/plan-cards/attAchDiscountExperiment/useOfferInAttAchExperiment';
import { BasePath, ShopPages } from 'flows/tvi/constants';

const STATIC_LOGOS = [
  {
    url: '/images/xfinity.svg',
    alt: 'xfinity',
  },
  {
    url: '/images/att.svg',
    alt: 'att',
  },
  {
    url: '/images/spectrum.svg',
    alt: 'spectrum',
  },
];

const KIND = ItemKinds.TV_INTERNET;

const TV_INTERNET_COMMON_STATIC_DATA = {
  subtype: 'TVI',
  icon: 'WIFI',
  title: 'Set up internet / TV',
  action: {
    type: 'internal',
    route: '/tvi',
  },
} as const;

const TV_INTERNET_PROMO_STATIC_DATA = {
  ...TV_INTERNET_COMMON_STATIC_DATA,
  providers: STATIC_LOGOS,
} as const;

/* Different constants for UPO and Non-UPO (upo status is prop of make cards) */
const TV_INTERNET_INITIATE_STATIC_DATA = {
  ...TV_INTERNET_COMMON_STATIC_DATA,
  description: 'Compare rates and packages. Work or stream on day one.',
  props: {},
} as const;

const TV_INTERNET_INITIATE_STATIC_DATA_UPO = {
  ...TV_INTERNET_COMMON_STATIC_DATA,
  description:
    'Compare all available rates and packages, or confirm you’ve already purchased this.',
  props: {},
} as const;

const TV_INTERNET_COMPLETED_STATIC_DATA = {
  ...TV_INTERNET_INITIATE_STATIC_DATA,
  action: {
    type: 'internal',
    route: '/tvi/landing',
  },
} as const;

const TV_INTERNET_HERO_EXTRAS = {
  initiate: {
    heading: 'Find the best internet / TV for your new place.',
    ctaText: 'Explore options',
  },
  draft: {
    heading: 'Almost there! Finish booking to get connected on day 1.',
    ctaText: 'Finish setup',
  },
} as const;

export const statusToBadge = (status?: string): BadgeConfig | undefined => {
  if (status === 'started' || status === 'cancelled') {
    return Badges.RECENTLY_VIEWED;
  }
  if (status === 'unverified' || status === 'reserved') {
    // verify if unverified applies ?
    return Badges.ALMOST_DONE;
  }
  if (isTVICompleted({ status: status ?? '' })) {
    return Badges.DONE;
  }
  return undefined;
};

export const statusToImportance = (status?: string) => {
  if (status === 'unverified' || status === 'reserved') {
    return 1_850_001;
  }
  if (isTVICompleted({ status: status ?? '' })) {
    return 0;
  }
  return 1_100_000;
};

const serviceTextSetter = (services: HomeServicesOfferServices): string => {
  if (services.internet && services.tv) return 'internet + TV';
  if (services.internet) return 'internet';
  return 'TV';
};

const isValidReservation = (
  item: Item | null,
  reservation: HomeServicesReservation
) => {
  const tviItemReservationCode =
    item?.metadata?.order_details?.reservation?.code;
  const tviReservationCode = reservation?.code;

  return (
    tviItemReservationCode === undefined || // this means no order placed yet
    (tviItemReservationCode === tviReservationCode &&
      item?.status !== 'cancelled')
  );
};

export const makeTodayTabTVInternetCards = ({
  itemDefinitions,
  items,
  user,
}: DataForCards): CardDataWithHeroExtras[] => {
  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));
  const tvInternetItems = items.filter(itemOfKind(KIND));
  const status = findMostRecentTVIByStatus(tvInternetItems)?.status ?? '';
  const badge = statusToBadge(status);

  if (itemDef) {
    return [
      {
        type: 'PROMOTED',
        data: {
          ...TV_INTERNET_PROMO_STATIC_DATA,
          badge,
          status,
          moveId: user?.currentMove?.id || '',
        },
        ...(badge
          ? {}
          : {
              action: {
                type: 'internal',
                route: '/tvi',
              },
            }),
        heroExtras:
          TV_INTERNET_HERO_EXTRAS[
            tvInternetItems.length ? 'draft' : 'initiate'
          ],
        identifier: 'tv_internet_initiate',
        importanceScore: statusToImportance(status),
      },
    ];
  }
  return [];
};

export const makeEssentialsTabTVInternetCards = ({
  itemDefinitions,
  items,
  isUPO,
}: DataForCards & {
  isUPO: boolean;
}): EssentialCards => {
  const itemDef = itemDefinitions.find(enabledItemDefOfKind(KIND));
  const tvInternetItems = items.filter(itemOfKind(KIND));

  const priority = isUPO;

  // Get data object based on UPO status (have different text)
  const staticData = isUPO
    ? { ...TV_INTERNET_INITIATE_STATIC_DATA_UPO }
    : { ...TV_INTERNET_INITIATE_STATIC_DATA };

  const toDoCards: CardData[] = [];
  const completedCards: CardData[] = [];
  if (itemDef) {
    if (tvInternetItems.length === 0) {
      toDoCards.push({
        type: 'INITIATE',
        data: {
          ...staticData,
          action: {
            type: 'internal',
            //route: '/tvi/landing', //keep for after exp
            route: '/tvi',
          },
          priority,
        },
        identifier: 'tv_internet',
        importanceScore: 1_100_000,
      });
    } else {
      tvInternetItems.forEach(
        (tvInternetItem: { id: string; status: string }) => {
          const { status } = tvInternetItem;
          if (isTVICompleted(tvInternetItem)) {
            completedCards.push({
              type: 'INITIATE',
              data: {
                ...TV_INTERNET_COMPLETED_STATIC_DATA,
                badge: statusToBadge(status),
                priority,
              },
              identifier: `tv_internet_completed_${tvInternetItem.id}`,
              importanceScore: 1_100_000,
            });
          } else {
            toDoCards.push({
              type: 'INITIATE',
              data: {
                ...TV_INTERNET_INITIATE_STATIC_DATA,
                badge: statusToBadge(status),
                priority,
              },
              identifier: `tv_internet_${tvInternetItem.id}`,
              importanceScore: 1_100_000,
            });
          }
        }
      );
    }
  }
  return {
    toDoCards,
    completedCards,
  };
};

export const makeTVIReceiptsForVerticals = ({
  items,
  target,
  tviData,
  attAchAutopayDiscountEnabled,
}: VerticalCardDetails): ReceiptPayload[] => {
  const tviItems = items.filter(
    (item: typeof items) => item.type === 'tv_internet'
  );
  if (tviItems.length === 0) {
    return [];
  }
  const mostRecentTVI = findMostRecentTVIByStatus(tviItems);

  const tviCards = [];
  const reservation = tviData;
  const offer = reservation?.offer;
  const offerInAttAchExperiment = isOfferInAttAchExperiment(
    offer?.provider.id,
    offer?.code,
    attAchAutopayDiscountEnabled
  );

  if (offer && isValidReservation(mostRecentTVI, reservation)) {
    const price = offerInAttAchExperiment
      ? (offer.introductoryPrice?.price || offer.price) - 5
      : offer.introductoryPrice?.price || offer.price;
    const provider = offer.provider?.name;
    const speed = offer.services?.internet?.speed?.mbps;
    const service = serviceTextSetter(offer.services);
    const isTransfer = reservation?.preferences?.flow === 'TRANSFER';
    const line1 = isTransfer
      ? `${offer.provider?.name} Transfer`
      : `${provider} ${service}`;
    let line2 = offer.services?.tv?.channels
      ? `${offer.services.tv.channels?.count}+ TV Channels`
      : '';
    line2 = offer.services?.internet
      ? line2
      : `${line2.length > 0 ? line2.concat(', ') : ''}${
          price ? `$${+price.toFixed(2)}/mo` : ''
        }`;
    const line3 =
      speed && price ? `${speed} mbps, $${+price.toFixed(2)}/mo` : '';
    const badgeDetails = statusToBadge(mostRecentTVI?.status ?? '');

    tviCards.push({
      type: 'RECEIPT',
      identifier: `tv_internet_${mostRecentTVI?.id}`,
      data: {
        subtype: 'BASIC',
        badge: badgeDetails,
        heading: line1,
        description: line2,
        descriptionLine2: line3,
        action: {
          type: 'internal',
          route: isTransfer
            ? `${BasePath}${ShopPages.TransferReservation}`
            : target || '',
        },
      },
    } as ReceiptPayload);
  }

  return tviCards;
};
