import { useEffect } from 'react';
import { useUserData } from '../../../../../core/hooks/use-profile';
import { createFormStore } from '../common';
import { Address, CreditCard, PiiTokenizedData } from 'flows/tvi/types';
import { InstallationOptionsEnum } from '@updater/consumer-graph';

export type XFinityFormData = {
  flexDevices?: string;
  internetModemChoice: string;
  creditCheckMethod?: 'autopay' | 'deposit' | 'authorize-credit-check';
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  dob: string | PiiTokenizedData;
  smsOptIn: 'sms'[];
  priorAddress?: Address;
  creditCard?: Partial<CreditCard>;
  installationType: InstallationOptionsEnum;
  ssn?: PiiTokenizedData;
};

export enum XFinityPages {
  MODEMS = 'modems',
  PAYMENT = 'payment-method',
  OPTION = 'payment-option',
  INFO = 'payment-info',
  REVIEW = 'buy/review',
  DOB = 'dob',
  SUBMISSION = 'submission',
  ERROR = 'error',
}

export const omitKeys = [
  'formData.dob',
  'formData.ssn',
  'formData.creditCard.expirationDate',
  'formData.creditCard.pci',
];

export const omitKeysForLogging = ['dob', 'ssn', 'creditCard'];

export const useStore = createFormStore<XFinityFormData, XFinityPages>(
  XFinityPages.MODEMS,
  {},
  omitKeys
);

export const getXFinitiyFormState = () => useStore.getState();

export const useXFinityFormStore = (...args: Parameters<typeof useStore>) => {
  const status = useStore((state) => state.status);
  const initializeFormData = useStore((state) => state.initializeFormData);
  const { loading: userDataLoading, user } = useUserData();

  const hasHydrated = useStore.persist.hasHydrated();

  useEffect(() => {
    if (!userDataLoading && hasHydrated && status !== 'loaded') {
      const { firstName, lastName, email, phone, currentMove } = user || {};
      const { unit, state, street, city, postalCode } =
        currentMove?.fromAddress || {};

      const initialData: Partial<XFinityFormData> = {
        internetModemChoice: 'internetModemOwned',
        creditCheckMethod: 'autopay',
        installationType: InstallationOptionsEnum.self,
        // Removing flexDevice for now until its pricing is implemented
        // Reference story https://updater.atlassian.net/browse/UPA-6
        // flexDevices: 'one',
        firstName: firstName ?? undefined,
        lastName: lastName ?? undefined,
        email: email ?? undefined,
        phone: phone ?? undefined,
        creditCard: {
          firstName: firstName ?? undefined,
          lastName: lastName ?? undefined,
          address: {
            street: street ?? '',
            unit: unit ?? '',
            city: city ?? '',
            state: state ?? '',
            postalCode: postalCode ?? '',
          },
        },
        priorAddress: <Address>{
          street: user?.currentMove?.fromAddress.street ?? undefined,
          unit: user?.currentMove?.fromAddress.unit ?? '',
          city: user?.currentMove?.fromAddress.city ?? undefined,
          state: user?.currentMove?.fromAddress.state ?? undefined,
          postalCode: user?.currentMove?.fromAddress.postalCode ?? undefined,
        },
      };

      initializeFormData({
        formData: initialData,
      });
    }
  }, [status, user, userDataLoading, hasHydrated, initializeFormData]);

  return useStore(...args);
};
