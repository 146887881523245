import React from 'react';

export interface TermsAndAgreementsProps {
  tracker?: any;
}

export interface ReviewSchema {
  notice?: { title: string; description: string };
  TermsAndAgreements: React.ComponentType<TermsAndAgreementsProps>;
}
