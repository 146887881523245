/**
 * Experiment from consumer-pod. Remove Phone Step from TVI Quiz.
 * Ticket for this: https://app.shortcut.com/updater/story/100180/remove-phone-step-from-tvi-quiz
 */
export const CONSUMER_TELCO_SKIP_PHONE_QUIZ = {
  NAME: 'consumer_telco_skip_phone_quiz',
  VARIANTS: {
    CONTROL: 'control',
    SKIP_PHONE_STEP: 'skip_phone_step',
  },
};

/**
 * Ticket - https://updater.atlassian.net/browse/UCA-130
 * Optimizely - https://app.optimizely.com/v2/projects/11181882450/experiments/24199871126/variations
 */
export const CONSUMER_APP_TVI_ATT_SHOWCASE = {
  NAME: 'consumer_app_tvi_att_showcase',
  VARIANTS: {
    CONTROL: 'control',
    FIBER_300: 'fiber_300',
    FIBER_500: 'fiber_500',
  },
};

/* Ticket - https://updater.atlassian.net/browse/APP-44
 * Growthbook - https://growthbook.prod.updatron.tools/features/consumer_app_fcc_bbnl
 */
export const CONSUMER_APP_FCC_BBNL = {
  NAME: 'consumer_app_fcc_bbnl',
  VARIANTS: {
    CONTROL: 'control',
    FCC_COMPLIANT: 'fcc_compliant',
  },
};

/* Ticket - https://updater.atlassian.net/browse/APP-467
 * Growthbook - https://growthbook.prod.updatron.tools/features/consumer_app_offers_showcase_redesign
 */
export const CONSUMER_APP_SHOWCASE_REDESIGN = {
  NAME: 'consumer_app_offers_showcase_redesign',
  VARIANTS: {
    CONTROL: 'control',
    REDESIGN: 'redesign',
  },
};

/**
 * Ticket - https://updater.atlassian.net/browse/TVI-1086
 * Growthbook - https://growthbook.prod.updatron.tools/features/consumer_app_spectrum_exclusive_provider
 */
export const CONSUMER_APP_SPECTRUM_EXCLUSIVE_PROVIDER = {
  NAME: 'consumer_app_spectrum_exclusive_provider',
  VARIANTS: {
    CONTROL: 'control',
    EXCLUSIVE_PROVIDER_ON: 'exclusive_provider_on',
  },
};

export const CONSUMER_APP_XFINITY_TRANSFER_RESERVATION = {
  NAME: 'consumer_app_xfinity_transfer_reservation',
  VARIANTS: {
    CONTROL: 'control',
    CALL_IN: 'call_in',
  },
};
