import { InternetProvider } from 'flows/tvi/types';
import {
  ProductTypes,
  AdditionalLanguagesTypes,
  SortMethod,
} from '@updater/ui-widgets';

export * from './shop-flow';
export * from './buy-flow';

export const CloudinaryBaseUrl =
  '//res.cloudinary.com/updater-production/image/upload';

export const LandingPage = '/';

export const Domain = 'home_services';

export const StorageKey = 'upd.tvi.state.shopflow';

export const CentralRegionStates = [
  'AL',
  'AR',
  'FL',
  'GA',
  'IL',
  'IN',
  'KY',
  'LA',
  'MI',
  'MS',
  'SC',
  'TN',
  'IN',
];

export const InternetProviderNames: Record<string, string> = {
  [InternetProvider.Att]: 'AT&T',
  [InternetProvider.Xfinity]: 'Xfinity',
  [InternetProvider.CenturyLink]: 'CenturyLink',
  [InternetProvider.Cox]: 'Cox',
  [InternetProvider.EarthLink]: 'EarthLink',
  [InternetProvider.Spectrum]: 'Spectrum',
  [InternetProvider.Optimum]: 'Optimum',
  [InternetProvider.Frontier]: 'Frontier',
  [InternetProvider.Verizon]: 'Verizon',
};

export const TvProviderNames = {
  [InternetProvider.Att]: 'DIRECTV Stream',
  [InternetProvider.Xfinity]: 'Xfinity',
  [InternetProvider.Cox]: 'Contour TV',
};

export enum OrderNotes {
  FeesMayChange = 'Additional equipment, taxes & fee subject to change',
}

export enum PhoneNumbers {
  Error = ' 866-586-7668',
  BuyFlow = '844-341-4838',
  // NOTE: see usePhoneNumber() hook
  // [... removed unused numbers]
}

export enum SupportInformation {
  NotStarted = 'Have questions? We’re here to help.',
  InProgress = 'Need help placing your order?',
  Completed = 'Questions about your order?',
}

export const DefaultOfferFilters = {
  sortMethod: SortMethod.PriceLowToHigh,
  selections: {
    products: [],
    providers: [],
    additionalLanguages: [],
  },
};

export type FilterSelections = {
  products: ProductTypes[];
  providers: string[];
  additionalLanguages: AdditionalLanguagesTypes[];
};
