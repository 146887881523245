import { FallbackOffers } from '@updater/ui-widgets';
import { InternetProvider } from 'flows/tvi/types/internet-providers';
import { availableOffers_availableOffers_offers_TvInternetLinkoutOffer as TvInternetLinkoutOffer } from 'types/generated/AvailableOffers';
import { ServiceabilityTermUnit } from 'types/generated/globalTypes';

export const BasePath = '/tvi/';
export const ShopBasePath = `${BasePath}shop/`;

export const QuizBasePath = `${BasePath}quiz/`;

export enum Experiments {
  AsyncShopAllPlans = 'async_shop_all_plans',
}

export enum ShopPages {
  Exp = 'exp',
  Offers = 'offers',
  Modems = 'modems',
  Review = 'review',
  Submission = 'submission',
  Error = 'error',
  Reservation = 'reservation',
  QuizStart = 'internet-users',
  TransferReservation = 'transfer/reservation',
}

export enum ShopAnalyticsEvents {
  Exp = 'shop_flow_exp',
  Offers = 'shop_flow_internet-offers',
  Error = 'shop_flow_error',
  ModalStreamingDevice = 'shop_flow_modal_streaming_device',
  ModalEditPlan = 'shop_flow_modal_edit-plan',
  ModalSpeedRanges = 'shop_flow_modal_speed-ranges',
  ReservationFail = 'shop_flow_reservation-fail',
  CompareAllPlans = 'shop_flow_compare-plans-button',
  CallNowButton = 'shop_flow_call-now-button',
}

export const LogosBaseUrl =
  'https://res.cloudinary.com/updater-production/image/upload/tv-internet/provider-logos/full-color/left-aligned/';

export const TvLogosBaseUrl =
  'https://res.cloudinary.com/updater-production/image/upload/tv-internet/tv-provider-logos/black-white/center-aligned/';

export const LoaderMinimumWait = 3000;

export const ProviderSortOrder: string[] = [
  InternetProvider.Xfinity,
  InternetProvider.Att,
  InternetProvider.Cox,
  InternetProvider.CenturyLink,
  InternetProvider.EarthLink,
];

const nonIntegratedTMobileProviderSummary = {
  availability: 'available',
  queryAddress: {
    street: null,
    unit: null,
    city: null,
    state: null,
    postalCode: null,
    __typename: 'ServiceabilityAddress',
  },
  addressSuggestions: [],
  status: 'cache',
  error: null,
  provider: {
    linkout: true,
    id: 't-mobile_5g_home_internet',
    code: 't-mobile_5g_home_internet',
    name: 't-mobile_5g_home_internet',
    logo: {
      url: 'https://res.cloudinary.com/updater-staging/image/upload/tv_internet_provider_logo_tmobile_5g_home_internet_bcwcxs.png',
    },
    featured: null,
    exclusive: null,
    integrated: null,
    displayName: null,
    url: null,
    phoneNumber: null,
    validationSteps: null,
    config: null,
    __typename: 'ServiceabilityProvider',
  },
  __typename: 'ServiceabilityProviderSummaries',
};

const nonIntegratedTMobileOffer: TvInternetLinkoutOffer = {
  url: 'https://tmobile.prf.hn/click/camref:1100ltYIK',
  code: 't-mobile_5g_home_internet-offer',
  providerCode: 't-mobile_5g_home_internet',
  name: 'T-Mobile 5G Home Internet',
  contract: null,
  disclaimer: null,
  disclaimers: null,
  proximityDisclaimer: null,
  highlights: null,
  extendedHighlights: null,
  promotions: null,
  pricing: {
    price: null,
    charges: null,
    term: {
      unit: 'month' as ServiceabilityTermUnit,
      length: null,
    },
  },
  tags: null,
  metadata: {
    fromCache: null,
    cachedAt: null,
    misc: {
      tagLine:
        'Get T-Mobile Home Internet for $50/month with AutoPay. Availability limited.',
    },
    dummy: true,
  },
  products: {
    internet: {
      name: 'Internet',
      downloadSpeed: null,
      uploadSpeed: null,
      type: 'wireless',
      features: [],
    },
    phone: null,
    tv: null,
  },
} as TvInternetLinkoutOffer;

export const fallbackOffers: FallbackOffers = [
  [nonIntegratedTMobileOffer],
  [nonIntegratedTMobileProviderSummary],
] as unknown as FallbackOffers;
